@import './partials/landing';
@import './partials/section';
@import './partials/utils';
@import './partials/photo-viewer';

* {
    box-sizing: border-box;
    max-width: 100vw;
    margin: 0;
  }
  
html {
    scroll-behavior: smooth;
}

.app {
  display: flex;
  justify-content: center;
}
