@import './breakpoints';

.photo-viewer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__overlay {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1;
    }

    &__img-text-wrapper {
        max-width: 70%;
        max-height: 70%;
        width: fit-content;
        height: 70%;
        position: relative;
        z-index: 2;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;

        @include tablet {
            max-width: 90%;
            max-height: 90%;
            width: auto;
            height: auto;
        }

        @include mobile {
            max-width: 90%;
            max-height: 90%;
            width: auto;
            height: auto;
        }
    }

    &__text-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: fit-content;
        background-color: #F1F2EE;
        padding-left: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 2px 1px 28px 1px rgba(0,0,0,0.6);

        @include mobile {
            font-size: 18px;
        }
    }

    &__img {
        height: 100%;
    }

    &__close { 
    
        &-btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 30px;
            background: none;
            padding: 0;
            border: none;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.4);;
        }

        &-img {
            width: 100%;
            height: 100%;
        }
    }
}