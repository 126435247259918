@import './fonts';
@import './breakpoints';

.landing {
    font-family: "Patrick Hand SC", sans-serif;
    font-size: 24px;
    color: #313335;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60rem;
    padding: 0 4rem 5rem 4rem;
    overflow: hidden;

    @include tablet {
        font-size: 20px;
        padding: 0 1rem 5rem 1rem;
    }

    @include mobile {
        font-size: 20px;
        padding: 0 1rem 5rem 1rem;
    }

    &__header {
        display: flex;
        justify-content: center;
        height: 5.93rem;
        color: #45474A;
        margin-top: 1rem;

        @include tablet {
            height: 4rem;
        }

        @include mobile {
            height: 4rem;
        }

        &-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &-main {
            font-size: 64px;
        }

        &-sub {
            font-size: 16px;
        }
    }

    &__main {
        margin-top: 4rem;
        width: 100%;

        &-texts-wrapper {
            display: flex;
            gap: 3rem;
            justify-content: space-between;

            @include tablet {
                flex-direction: column;
            }
    
            @include mobile {
                flex-direction: column;
            }
        }

        &-header-text {
            margin-bottom: 1rem;
            
            @include tablet {
                max-width: 100%;
                width: 25rem;
            }
    
            @include mobile {
                max-width: 100%;
                width: 25rem;
            }
        }

        &-text {
            margin-bottom: 1rem;

            @include tablet {
                max-width: 100%;
                width: 25rem;
            }
    
            @include mobile {
                max-width: 100%;
                width: 25rem;
            }
        }

        &-wrapper-text {
            width: 26rem;

            @include tablet {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
    
            @include mobile {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        &-contact {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            & a {
                text-decoration: none;
                cursor: pointer;
            }

            &-btn {
                width: 5.625rem;
                height: 2.062rem;
                border: none;
                background-color: #EF9457;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: .2rem;
                border-radius: 3px;
                cursor: pointer;

                &-text {
                    font-family: "Patrick Hand SC", sans-serif;
                    font-size: 22px;
                    color: #313335;
                    padding-bottom: .15rem;
                }

                &-icon {
                    width: 100%;
                    height: 100%;

                    &-wrapper {
                        width: 1.187rem;
                        height: 1.187rem;
                    }
                }
            }

            &-links {
                display: flex;
                gap: .5rem;
                margin-top: .5rem;
            }

            &-link {
                height: 2.062rem;
                width: 2.062rem;
                color: #6184D8;
            }
        }

        &-text-contact {
            width: fit-content;
        }

        &-contact-links {
            width: fit-content;
        }
    }
}