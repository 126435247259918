@import './breakpoints';

.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;

    &__title {
        font-size: 32px;
        width: fit-content;

        &-underline {
            width: 9.62rem;
            height: .187rem;
            display: block;
            margin-bottom: 3rem;
            margin-top: .2rem;
            border-radius: 2px;

            &_color {

                &_one {
                    background-color: #EF9457;
                    transform: rotate(1deg);
                }

                &_two {
                    background-color: #6184D8;
                    transform: rotate(-1deg);
                }
            }
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        width: 100%;

        @include tablet {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        @include mobile {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &-wrapper {
            width: 28rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include tablet {
                width: 100%;
            }

            @include mobile {
                width: 100%;
            }
        }

        &_layout_inverted {
            flex-direction: row-reverse;

            @include tablet {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            @include mobile {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__text {
        width: 20rem;

        @include tablet {
            padding-left: 1rem;
        }

        @include mobile {
            padding-left: 1rem;
        }
    }

    &__img {
        width: 100%;

        &-wrapper {
            width: 19rem;

            @include tablet {
                width: 15rem;
            }

            @include mobile {
                width: 15rem;
            }
        }
    }

    &__gallery {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        max-height: 27.2rem;
        overflow: auto;
        
        @include tablet {
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        @include mobile {
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        &-item {
            max-width: 8.5rem;
            max-height: 8.5rem;
            overflow: hidden;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &-img {
                width: 100%;
                transform: scale(175%);
                border-radius: 4px;
            }

            @include tablet {
                max-width: 45%;
            }

            @include mobile {
                max-width: 45%;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        list-style-type: none;
        margin: 0;
        padding: 0;

        &-item {
            display: flex;
            gap: 1.5rem;
            max-height: 21.7rem;
            align-items: center;

            &-img {
                border-radius: 4px;
                width: 100%;
                transform: scale(175%);

                &-wrapper {
                    max-width: 8.5rem;
                    max-height: 8.5rem;
                    border-radius: 4px;
                    overflow: hidden;
                    cursor: pointer;
                }
            }
        }
    }
}