@font-face {
  font-family: "Patrick Hand SC";
  src: url("../assets/fonts/PatrickHandSC-Regular.ttf");
}
.landing {
  font-family: "Patrick Hand SC", sans-serif;
  font-size: 24px;
  color: #313335;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60rem;
  padding: 0 4rem 5rem 4rem;
  overflow: hidden;
}
@media (max-width: 768px) {
  .landing {
    font-size: 20px;
    padding: 0 1rem 5rem 1rem;
  }
}
@media (max-width: 400px) {
  .landing {
    font-size: 20px;
    padding: 0 1rem 5rem 1rem;
  }
}
.landing__header {
  display: flex;
  justify-content: center;
  height: 5.93rem;
  color: #45474A;
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .landing__header {
    height: 4rem;
  }
}
@media (max-width: 400px) {
  .landing__header {
    height: 4rem;
  }
}
.landing__header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.landing__header-main {
  font-size: 64px;
}
.landing__header-sub {
  font-size: 16px;
}
.landing__main {
  margin-top: 4rem;
  width: 100%;
}
.landing__main-texts-wrapper {
  display: flex;
  gap: 3rem;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .landing__main-texts-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 400px) {
  .landing__main-texts-wrapper {
    flex-direction: column;
  }
}
.landing__main-header-text {
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .landing__main-header-text {
    max-width: 100%;
    width: 25rem;
  }
}
@media (max-width: 400px) {
  .landing__main-header-text {
    max-width: 100%;
    width: 25rem;
  }
}
.landing__main-text {
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .landing__main-text {
    max-width: 100%;
    width: 25rem;
  }
}
@media (max-width: 400px) {
  .landing__main-text {
    max-width: 100%;
    width: 25rem;
  }
}
.landing__main-wrapper-text {
  width: 26rem;
}
@media (max-width: 768px) {
  .landing__main-wrapper-text {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 400px) {
  .landing__main-wrapper-text {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.landing__main-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.landing__main-contact a {
  text-decoration: none;
  cursor: pointer;
}
.landing__main-contact-btn {
  width: 5.625rem;
  height: 2.062rem;
  border: none;
  background-color: #EF9457;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}
.landing__main-contact-btn-text {
  font-family: "Patrick Hand SC", sans-serif;
  font-size: 22px;
  color: #313335;
  padding-bottom: 0.15rem;
}
.landing__main-contact-btn-icon {
  width: 100%;
  height: 100%;
}
.landing__main-contact-btn-icon-wrapper {
  width: 1.187rem;
  height: 1.187rem;
}
.landing__main-contact-links {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.landing__main-contact-link {
  height: 2.062rem;
  width: 2.062rem;
  color: #6184D8;
}
.landing__main-text-contact {
  width: fit-content;
}
.landing__main-contact-links {
  width: fit-content;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.section__title {
  font-size: 32px;
  width: fit-content;
}
.section__title-underline {
  width: 9.62rem;
  height: 0.187rem;
  display: block;
  margin-bottom: 3rem;
  margin-top: 0.2rem;
  border-radius: 2px;
}
.section__title-underline_color_one {
  background-color: #EF9457;
  transform: rotate(1deg);
}
.section__title-underline_color_two {
  background-color: #6184D8;
  transform: rotate(-1deg);
}
.section__body {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
}
@media (max-width: 768px) {
  .section__body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 400px) {
  .section__body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.section__body-wrapper {
  width: 28rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .section__body-wrapper {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .section__body-wrapper {
    width: 100%;
  }
}
.section__body_layout_inverted {
  flex-direction: row-reverse;
}
@media (max-width: 768px) {
  .section__body_layout_inverted {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 400px) {
  .section__body_layout_inverted {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.section__text {
  width: 20rem;
}
@media (max-width: 768px) {
  .section__text {
    padding-left: 1rem;
  }
}
@media (max-width: 400px) {
  .section__text {
    padding-left: 1rem;
  }
}
.section__img {
  width: 100%;
}
.section__img-wrapper {
  width: 19rem;
}
@media (max-width: 768px) {
  .section__img-wrapper {
    width: 15rem;
  }
}
@media (max-width: 400px) {
  .section__img-wrapper {
    width: 15rem;
  }
}
.section__gallery {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-height: 27.2rem;
  overflow: auto;
}
@media (max-width: 768px) {
  .section__gallery {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 400px) {
  .section__gallery {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
.section__gallery-item {
  max-width: 8.5rem;
  max-height: 8.5rem;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.section__gallery-item-img {
  width: 100%;
  transform: scale(175%);
  border-radius: 4px;
}
@media (max-width: 768px) {
  .section__gallery-item {
    max-width: 45%;
  }
}
@media (max-width: 400px) {
  .section__gallery-item {
    max-width: 45%;
  }
}
.section__list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.section__list-item {
  display: flex;
  gap: 1.5rem;
  max-height: 21.7rem;
  align-items: center;
}
.section__list-item-img {
  border-radius: 4px;
  width: 100%;
  transform: scale(175%);
}
.section__list-item-img-wrapper {
  max-width: 8.5rem;
  max-height: 8.5rem;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.margin-left-auto {
  margin-left: auto;
}
@media (max-width: 768px) {
  .margin-left-auto {
    margin: auto;
  }
}
@media (max-width: 400px) {
  .margin-left-auto {
    margin-left: auto;
  }
}

.photo-viewer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-viewer__overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
.photo-viewer__img-text-wrapper {
  max-width: 70%;
  max-height: 70%;
  width: fit-content;
  height: 70%;
  position: relative;
  z-index: 2;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
@media (max-width: 768px) {
  .photo-viewer__img-text-wrapper {
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
  }
}
@media (max-width: 400px) {
  .photo-viewer__img-text-wrapper {
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
  }
}
.photo-viewer__text-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  background-color: #F1F2EE;
  padding-left: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 2px 1px 28px 1px rgba(0, 0, 0, 0.6);
}
@media (max-width: 400px) {
  .photo-viewer__text-wrapper {
    font-size: 18px;
  }
}
.photo-viewer__img {
  height: 100%;
}
.photo-viewer__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
}
.photo-viewer__close-img {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  max-width: 100vw;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

.app {
  display: flex;
  justify-content: center;
}