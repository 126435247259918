@mixin tablet {
    @media (max-width: 768px) {
      @content;
    }
  }
  
  @mixin mobile {
    @media (max-width: 400px) {
      @content;
    }
  }